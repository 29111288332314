import enAU from 'date-fns/locale/en-AU';
import enUS from 'date-fns/locale/en-US';

const USDollarsFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const isAustralia = () => import.meta.env.VITE_SENTRY_ENV.endsWith('_au');

export const formatToUSDollars = (price: string | number | undefined) => {
  if (price === 0) return '$0';
  if (!price) {
    return null;
  }
  if (typeof price === 'string') {
    if (Number.isInteger(Number(price))) {
      return `$${price}`;
    } else {
      return `$${Number(price).toFixed(2)}`; // If it has decimals, return with two decimal places
    }
  }
  if (typeof price === 'number') {
    if (Number.isInteger(price)) {
      return `$${price.toString()}`;
    } else {
      return `$${price.toFixed(2)}`;
    }
  }
};

export const formatPhoneNumber = (number: string | undefined) => {
  if (number) {
    return isAustralia()
      ? number.replace(/\D+/g, '').replace(/([0-9]{2})([0-9]{4})([0-9]{4}$)/gi, '($1) $2 $3')
      : number.replace(/\D+/g, '').replace(/([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, '($1) $2-$3');
  } else {
    return '';
  }
};

export const isCardTypeAmex = (cardNumber: string) =>
  cardNumber?.indexOf('34') === 0 || cardNumber?.indexOf('37') === 0;

export const findDebitCardType = (cardNumber: string) => {
  if (isCardTypeAmex(cardNumber)) {
    return '0000-000000-00000';
  } else {
    return '0000-0000-0000-0000';
  }
};

export const getAdapterLocale = () => (isAustralia() ? enAU : enUS);

export const isUS = isAustralia() ? false : true;

export function parseStaffId(staffId: string | number | undefined): number[] {
  if (!staffId) return [];

  let input = staffId;

  if (typeof input === 'number') {
    input = input.toString();
  }

  return input
    .split(',')
    .map((s) => parseFloat(s.trim()))
    .filter((num) => !isNaN(num));
}
