import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { LessonAppointmentAvailability } from 'common/dist/infrastructure/modules/appointment/interfaces/AvailabilityTypes';
import { decode } from 'html-entities';
import React, { FC } from 'react';
import { isToday } from 'date-fns';

import HorizontalCard, { HorizontalCardType } from 'infrastructure/components/HorizontalCard';
import HorizontalCardSkeleton from 'infrastructure/components/HorizontalCard/HorizontalCardSkeleton';
import {
  selectLocationDetails,
  selectReservationDate,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import {
  formatPhoneNumber,
  formatToUSDollars,
} from 'infrastructure/targets/web/modules/common/helpers';
import { getLessonBookingImage } from 'infrastructure/targets/web/modules/common/imageUrlHelpers';

interface IInstructorsList {
  instructorsList?: Array<LessonAppointmentAvailability>;
  loading?: boolean;
  onInstructorSelect?: (instructor: string) => void;
  onCardClick?: (value: number) => void;
}

const InstructorsList: FC<IInstructorsList> = ({ instructorsList, loading, onCardClick }) => {
  const reservationDate = useAppSelector(selectReservationDate);
  const selectedLocationDetails = useAppSelector(selectLocationDetails);
  const phoneNumber = selectedLocationDetails?.telephone;
  const handleCardClick = (value: number) => {
    if (onCardClick) {
      onCardClick(value);
    }
  };

  const getButtonTitle = (title: string) => {
    if (title) {
      if (/1 Hour/g.test(title) && !/Intro/g.test(title)) {
        return '1 Hour';
      }
      if (/0.5 Hour/g.test(title)) {
        return '0.5 Hour';
      }
      if (/.5 Hour/g.test(title)) {
        return '0.5 Hour';
      }
      if (!/group/g.test(title)) {
        return 'Small Group';
      }
    }
  };

  const getButtonList = (instructorId: number) => {
    const selectedInstructor = instructorsList?.find(
      (insctructor) => insctructor.staffId === instructorId,
    );
    return selectedInstructor?.services
      ?.filter((service) => !/Intro/g.test(service?.title || ''))
      ?.sort((a, b) => a.price - b.price)
      ?.map((service) => ({
        title: getButtonTitle(service.title),
        value: service.sessionTypeId,
        subtitle: formatToUSDollars(service.price),
      }));
  };

  return (
    <>
      {!loading && !instructorsList?.length && (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h5" textAlign="center">
            {isToday(new Date(reservationDate ?? '0'))
              ? `Same-day bookings are not available online. Call ${formatPhoneNumber(
                  phoneNumber,
                )} to book a same day lesson or to get more information. Please use the date selector for all future date availability.`
              : `Sorry, but there are no instructors available`}
          </Typography>
        </Box>
      )}
      {loading ? (
        <Box sx={{ width: '100%', display: 'flex', gap: 4, flexDirection: 'column' }}>
          <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6">Fetching available instructors...</Typography>
          </Box>
          <HorizontalCardSkeleton />
          <HorizontalCardSkeleton />
        </Box>
      ) : (
        instructorsList?.map((instructor) => (
          <HorizontalCard
            key={instructor.staffId}
            buttonList={getButtonList(instructor?.staffId)}
            variant={HorizontalCardType.Large}
            chipLabel={instructor.staffLevel}
            title={`${instructor.staffFirstName} ${instructor.staffLastName}`}
            handleCTA={() => handleCardClick(instructor.staffId)}
            content={decode(instructor?.staffBio)}
            imageUrl={instructor.staffImage ? instructor.staffImage : getLessonBookingImage()}
            noBottomMargin
          />
        ))
      )}
    </>
  );
};

export default InstructorsList;
