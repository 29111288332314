import { AppointmentTypes } from 'common';
import { ServicePricing } from 'common/dist/infrastructure/modules/appointment/interfaces/AppointmentTypes';
import { UserSummary } from 'common/dist/infrastructure/modules/auth/interfaces/UserTypes';
import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';

import Appointment from 'domain/entities/Appointment';
import { WizardStep } from 'domain/entities/WizardStep';
import { AsyncHookResult, InjectableHook, useHookInjection } from 'domain/hooks';
import { formatPricing } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/helpers';

import {
  BookingWizardNavigationHook,
  IBookingWizardNavigationHook,
} from './useCaseNavigateBookingWizard';
import { ExternalNavigationHook, IExternalNavigationHook } from './useCaseNavigateTo';

export enum ConfirmReservationError {
  Unknown = 'unknown',
  AppointmentUnavailable = 'appointment_unavailable',
  Unauthorized = 'unauthorized',
  InvalidPromoCode = 'invalid_promo_code',
}

export enum ServicePricingError {
  Unknown = 'unknown',
}

export enum VerifyPromoError {
  Valid = 'valid',
  CodeNotActivated = 'CodeNotActivated',
  CodeDeactivated = 'CodeDeactivated',
  InvalidDOW = 'InvalidDOW',
  NonExistentCode = 'NonExistentCode',
}

export interface BookAppointmentValues {
  locationId: string;
  body: AppointmentTypes.BookAppointmentRequest;
}

export const ConfirmReservationAdapter = Symbol('ConfirmReservationAdapter');
export type IConfirmReservationAdapter = InjectableHook<
  AsyncHookResult<AppointmentTypes.BookedAppointmentResponse, ConfirmReservationError> & {
    bookAppointment: (values: BookAppointmentValues) => void;
    handleLogout: () => void;
    accessToken: string;
    getUser: () => Promise<UserSummary> | undefined;
    getPricing: (userSummary?: UserSummary | undefined) => void;
    pricingResult: ServicePricing[] | undefined;
    isPricingLoading: boolean;
    pricingError: ServicePricingError | undefined;
    handleSaveUserDataToStore: (userSummary: UserSummary | undefined) => void;
    handleSaveAppointmentsToStore: (
      appointments: Appointment[] | undefined,
      total: number | undefined,
      addOn?: AppointmentTypes.AddOnType | undefined,
    ) => void;
    handleSaveConfirmedBookingToStore: (result: AppointmentTypes.BookedAppointmentResponse) => void;
    handleMyBookingsRedirect: () => void;
  }
>;

const useCaseConfirmReservation = (currentStep?: WizardStep) => {
  const bookingWizardNavigation = useHookInjection<IBookingWizardNavigationHook>(
    BookingWizardNavigationHook,
  );
  const externalNavigation = useHookInjection<IExternalNavigationHook>(ExternalNavigationHook);
  const [totalToPay, setTotalToPay] = useState<number | undefined>();
  const [formattedAppointments, setFormattedAppointments] = useState<Appointment[]>();
  const adapter = useInjection<IConfirmReservationAdapter>(ConfirmReservationAdapter);
  const {
    error,
    result,
    inProgress,
    bookAppointment,
    handleLogout,
    accessToken,
    getUser,
    getPricing,
    pricingResult,
    isPricingLoading,
    pricingError,
    handleSaveUserDataToStore,
    handleSaveAppointmentsToStore,
    handleSaveConfirmedBookingToStore,
    handleMyBookingsRedirect,
  } = adapter();

  const handleNextStep = async (values: BookAppointmentValues) => {
    await bookAppointment(values);
  };

  useEffect(() => {
    if (!error && result) {
      handleSaveConfirmedBookingToStore(result);
      if (currentStep) {
        bookingWizardNavigation.redirectToNextStep(currentStep);
      }
    }
  }, [error, result]);

  useEffect(() => {
    if (!accessToken || accessToken === '') {
      externalNavigation.redirectToSignInPage();
    }

    if (accessToken) {
      const fetchUserDataAndPricing = async () => {
        const payload = await getUser();
        handleSaveUserDataToStore(payload);
        getPricing(payload);
      };

      fetchUserDataAndPricing();
    }
  }, [accessToken]);

  useEffect(() => {
    if (pricingResult && !error) {
      const pricings = formatPricing(pricingResult);
      const total = pricings.appointments?.reduce(
        (total, item) => Number(total) + Number(item.price),
        0,
      );
      setTotalToPay(total);
      setFormattedAppointments(pricings.appointments);
      handleSaveAppointmentsToStore(pricings.appointments, total, pricings.addOn);
    }
  }, [pricingResult]);

  return {
    error,
    result,
    inProgress,
    appointments: formattedAppointments,
    totalToPay: totalToPay,
    isPricingLoading: isPricingLoading,
    pricingError,
    logout: handleLogout,
    nextStep: handleNextStep,
    prevStep: () => {
      if (currentStep) {
        bookingWizardNavigation.redirectToPrevStep(currentStep);
      }
    },
    handleMyBookingsRedirect,
  };
};

export default useCaseConfirmReservation;
