import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';
import countryList from 'react-select-country-list';

import {
  IPaymentDetailsAdapter,
  PaymentDetailsError,
  PaymentDetailsForm,
} from 'application/modules/bookingWizard/useCases/hooks/useCasePaymentDetails';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
import { selectAccessToken } from 'infrastructure/redux/slices/auth.selector';
import {
  selectExperienceType,
  selectLocation,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';

type QueryOrSerializedError =
  | FetchBaseQueryError
  | (SerializedError & {
      status?: number;
    });

const getError = (error?: QueryOrSerializedError): PaymentDetailsError | undefined => {
  if (!error) {
    return undefined;
  }

  if (error?.status === 400) {
    return PaymentDetailsError.InvalidPaymentInfo;
  }

  return PaymentDetailsError.Unknown;
};

const usePaymentDetails: IPaymentDetailsAdapter = () => {
  const accessToken = useAppSelector(selectAccessToken);
  const user = useAppSelector(selectUserSummary);
  const hasCreditCardOnFile = !!user?.creditCard;
  const flowType = useAppSelector(selectExperienceType);
  const locationId = useAppSelector(selectLocation);

  const [updateClientData, { isLoading, error, data, isSuccess }] =
    mboAPI.useUpdatePaymentDetailsMutation();
  const [getUserData] = mboAPI.useLazyGetUserDataQuery();

  const handleFetchUserData = async () => {
    if (locationId) {
      await getUserData(locationId);
    }
  };

  const countries = useMemo(() => countryList().getData(), []);

  const handleUpdatePaymentData = async (locationId: string, values: PaymentDetailsForm) => {
    const body = {
      id: values.id,
      email: values.email,
      country: values.country,
      clientCreditCard: {
        address: values.address,
        cardHolder: values.cardHolder,
        cardNumber: values.cardNumber,
        cardType: values.cardType,
        city: values.city,
        expMonth: values.expMonth,
        expYear: values.expYear,
        postalCode: values.postalCode,
        state: values.state,
        ccv: values.ccv,
      },
    };
    await updateClientData({
      locationId,
      body,
    });
  };

  return {
    inProgress: isLoading,
    result: data,
    isSuccess,
    error: getError(error),
    countries,
    accessToken,
    updatePaymentData: handleUpdatePaymentData,
    hasCreditCardOnFile,
    flowType,
    fetchUserData: handleFetchUserData,
  };
};

export default usePaymentDetails;
