import { Typography } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { StyledContentCardContainer } from './style';

interface IContentCard {
  children?: ReactNode;
  title?: ReactNode;
}

const ContentCard: FC<IContentCard> = ({ children, title }) => (
  <StyledContentCardContainer>
    <Typography variant="h2">{title}</Typography>
    {children}
  </StyledContentCardContainer>
);

export default ContentCard;
