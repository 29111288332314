import { useNavigate } from 'react-router';

import { IExternalNavigationHook } from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateTo';
import { Variants } from 'common';
import { AccountRoutes } from 'infrastructure/targets/web/modules/bookingWizard/navigation/BookingWizardRoutes';
import { ManageBookingRoutes } from 'infrastructure/targets/web/modules/bookingWizard/navigation/ManageBookingRoutes';
import { isGenericDuckpin } from '../../common/imageUrlHelpers';

const isAustralia = import.meta.env.VITE_SENTRY_ENV === 'production_au';
const isDuckpin = import.meta.env.VITE_VARIANT === Variants.DUCKPIN;

const useExternalNavigation: IExternalNavigationHook = () => {
  const navigate = useNavigate();
  const openNewTabAndRedirectToContactPage = () => {
    isDuckpin
      ? isGenericDuckpin()
        ? openPageInANewTab('https://fiveirongolf.com/events')
        : openPageInANewTab('https://www.detroitduckpin.com/#info')
      : isAustralia
      ? openPageInANewTab('https://fiveirongolf.au/pages/events')
      : openPageInANewTab('https://fiveirongolf.com/events');
  };

  const redirectToCreateAccountPage = () => {
    navigateToPage(`${AccountRoutes.CreateAccount}`);
  };

  const openPageInANewTab = (pageUrl: string) => {
    const newWindow = window.open(pageUrl, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const navigateToPage = (pageSlug: string, state?: Record<string, unknown>) => {
    navigate(pageSlug, { state });
  };

  const redirectToSignInPage = () => {
    navigateToPage(AccountRoutes.SignIn);
  };

  const redirectToCheckYourEmailPage = (error?: unknown) => {
    navigateToPage(AccountRoutes.CheckYourEmail, { error: error });
  };

  const redirectToMyBookingsPage = () => {
    navigateToPage(ManageBookingRoutes.Index);
  };

  return {
    openNewTabAndRedirectToContactPage,
    redirectToCreateAccountPage,
    openPageInANewTab,
    navigateToPage,
    redirectToSignInPage,
    redirectToCheckYourEmailPage,
    redirectToMyBookingsPage,
  };
};

export default useExternalNavigation;
