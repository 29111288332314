import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RemoteConfigKey, RemoteConfigValues } from 'domain/entities/RemoteConfig';
import { initialCarouselItems } from './remoteConfig.initialCarouselItems';

export interface RemoteConfigState {
  config: RemoteConfigValues;
  loading: boolean;
  error: string | null;
}

export const remoteConfigInitialState: RemoteConfigState = {
  config: {
    [RemoteConfigKey.should_force_app_update]: false,
    [RemoteConfigKey.carousel_items]: initialCarouselItems,
    [RemoteConfigKey.enable_qr_code_checkin]: {},
  },
  loading: false,
  error: null,
};

const remoteConfigSlice = createSlice({
  name: 'remoteConfig',
  initialState: remoteConfigInitialState,
  reducers: {
    fetchConfigStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchConfigSuccess: (state, action: PayloadAction<Partial<RemoteConfigValues>>) => {
      state.loading = false;
      state.config = { ...remoteConfigInitialState.config, ...action.payload };

      // Ensure that carousel is never empty
      if (action.payload.carousel_items && action.payload.carousel_items.length < 1) {
        state.config.carousel_items = remoteConfigInitialState.config.carousel_items;
      }
    },
    fetchConfigFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchConfigStart, fetchConfigSuccess, fetchConfigFailure } =
  remoteConfigSlice.actions;

export default remoteConfigSlice.reducer;
