import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import {
  IAppStartupAdapter,
  RefreshTokenError,
} from 'application/modules/bookingWizard/useCases/hooks/useCaseAppStartup';
import { QueryOrSerializedError } from 'infrastructure/redux/adapters/auth/SignInAdapter';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
import { selectRefreshToken } from 'infrastructure/redux/slices/auth.selector';
import { setAccessToken, setRefreshToken } from 'infrastructure/redux/slices/auth.slice';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
const getError = (error?: QueryOrSerializedError): RefreshTokenError | undefined => {
  if (!error) {
    return undefined;
  }
};

const useAppStartup: IAppStartupAdapter = () => {
  const dispatch = useAppDispatch();
  const savedRefreshToken = useAppSelector(selectRefreshToken);
  const [sendRefreshToken, { isLoading, status, error, data }] =
    mboAPI.useRefreshAuthTokenMutation();

  const handleRefreshToken = () => {
    if (savedRefreshToken) {
      sendRefreshToken(savedRefreshToken);
    }
  };

  const saveNewTokensToStore = (newAccessToken: string, newRefreshToken: string) => {
    dispatch(setRefreshToken(newRefreshToken));
    dispatch(setAccessToken(newAccessToken));
  };

  const initializeSentry = async () => {
    try {
      const isProd = import.meta.env.VITE_SENTRY_ENV.startsWith('production');
      const isLocal = import.meta.env.VITE_SENTRY_ENV === 'local';
      if (!isLocal) {
        Sentry.init({
          environment: import.meta.env.VITE_SENTRY_ENV,
          dsn: import.meta.env.VITE_SENTRY_DSN,
          integrations: [
            new Sentry.Replay(),
            new Sentry.BrowserTracing({
              routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
              ),
            }),
          ],
          tracesSampleRate: isProd ? 0.1 : 0.3,
          replaysSessionSampleRate: 0.01,
          replaysOnErrorSampleRate: 0.1,
          tracePropagationTargets: [/.+/],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initializeFacebookPixel = async () => {
    try {
      const { default: ReactPixel } = await import('react-facebook-pixel');
      ReactPixel.init(import.meta.env.VITE_META_PIXEL, undefined, {
        autoConfig: true,
        debug: true,
      });
      ReactPixel.pageView();
      ReactPixel.track('PageView');
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateAppTitle = () => {
    try {
      document.title = import.meta.env.VITE_APP_TITLE;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    inProgress: isLoading,
    wasSuccesful: status === QueryStatus.fulfilled,
    error: getError(error),
    handleRefreshToken,
    handleUpdateAppTitle,
    newRefreshToken: data?.refreshToken,
    newAccessToken: data?.accessToken,
    saveNewTokensToStore,
    initializeFacebookPixel,
    initializeSentry,
  };
};

export default useAppStartup;
