import { useInjection } from 'inversify-react';
import { useEffect } from 'react';

import { InjectableHook, useHookInjection } from 'domain/hooks';

import { ExternalNavigationHook, IExternalNavigationHook } from './useCaseNavigateTo';

export const ReservationCompleteAdapter = Symbol('ReservationCompleteAdapter');

export type Guest = {
  name: string;
  email: string;
};

export type IReservationCompleteAdapter = InjectableHook<{
  handleMakeAnotherBooking: () => void;
  handleAddNextGuest: () => void;
  handleRemoveGuest: (guestToRemove: string) => void;
  guestMap: Array<Guest>;
  accessToken: string;
  handleLogout: () => void;
  getLocationUrl: () => string | undefined;
  handleSendWaiverLinks: (emails: Record<string, string>) => void;
  isWaiverLoading: boolean;
  emailsSentSuccesfuly: boolean;
  emailsError: boolean;
  displayError: (value: boolean) => void;
}>;

const useCaseReservationComplete = () => {
  const externalNavigation = useHookInjection<IExternalNavigationHook>(ExternalNavigationHook);
  const adapter = useInjection<IReservationCompleteAdapter>(ReservationCompleteAdapter);

  const {
    handleLogout,
    handleMakeAnotherBooking,
    handleAddNextGuest,
    guestMap,
    handleRemoveGuest,
    accessToken,
    getLocationUrl,
    handleSendWaiverLinks,
    isWaiverLoading,
    emailsSentSuccesfuly,
    emailsError,
    displayError,
  } = adapter();

  useEffect(() => {
    if (!accessToken || accessToken === '') {
      externalNavigation.redirectToSignInPage();
    }
  }, [accessToken]);

  const handleSignWaver = () => {
    const locationUrl = getLocationUrl();
    if (locationUrl) {
      externalNavigation.openPageInANewTab(locationUrl);
    }
  };

  const handleSendEmailsToGuests = (emails: Record<string, string>) => {
    handleSendWaiverLinks(emails);
  };

  const handleNextStep = (values: Record<string, string>) => {
    handleSendEmailsToGuests(values);
  };

  useEffect(() => {
    if (emailsSentSuccesfuly) {
      handleSignWaver();
    }
  }, [emailsSentSuccesfuly]);

  useEffect(() => {
    if (emailsError) {
      displayError(true);
    }
  }, [emailsError]);

  return {
    isWaiverLoading,
    guests: guestMap,
    removeGuest: handleRemoveGuest,
    makeAnotherBooking: handleMakeAnotherBooking,
    addNextGuest: handleAddNextGuest,
    logout: handleLogout,
    nextStep: handleNextStep,
  };
};

export default useCaseReservationComplete;
