import { Chip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, ReactNode } from 'react';

import ButtonsGroup, { IButtonGroupButton } from 'infrastructure/components/ButtonsGroup';

import {
  StyledBottomContainer,
  StyledCTAButton,
  StyledContentContainer,
  StyledHorizontalCardContainer,
  StyledInnerRightCardContainer,
  StyledTitleContainer,
} from './style';

export enum HorizontalCardType {
  Large = 'large',
  Small = 'small',
  NoCTA = 'noCTA',
}

interface IHorizontalCard {
  variant: HorizontalCardType;
  title: string;
  imageUrl: string;
  buttonList?: Array<IButtonGroupButton>;
  content?: ReactNode | string;
  chipLabel?: string;
  handleCTA?: (employeeId?: string) => void;
  employeeId?: string;
  noBottomMargin?: boolean;
}
const HorizontalCard: FC<IHorizontalCard> = ({
  title,
  variant,
  imageUrl,
  buttonList,
  chipLabel,
  handleCTA,
  employeeId,
  content,
  noBottomMargin,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const imageSizeVariant =
    variant === HorizontalCardType.Large || variant === HorizontalCardType.NoCTA
      ? { width: '180px', height: '180px' }
      : { width: '84px', height: '84px' };

  const imageSizeMobile = { width: '40px', height: '40px' };

  const imageSize = isMobile ? imageSizeMobile : imageSizeVariant;
  return (
    <StyledHorizontalCardContainer sx={noBottomMargin ? { marginBottom: 0 } : {}}>
      {!isMobile ? (
        <Box
          component={'img'}
          src={imageUrl}
          sx={{ ...imageSize, borderRadius: '8px', objectFit: 'cover' }}
        />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4, mb: 4 }}>
          <Box component={'img'} src={imageUrl} sx={{ ...imageSize, borderRadius: '8px' }} />
          <StyledTitleContainer>
            <Typography variant={chipLabel ? 'h4' : 'h5'} color="text.primary">
              {title}
            </Typography>
            {chipLabel && (
              <Chip variant="filled" size="medium" color="secondary" label={chipLabel} />
            )}
          </StyledTitleContainer>
        </Box>
      )}

      <StyledInnerRightCardContainer>
        {!isMobile && (
          <StyledTitleContainer>
            <Typography variant={chipLabel ? 'h4' : 'h5'} color="text.primary">
              {title}
            </Typography>
            {chipLabel && (
              <Chip variant="filled" size="medium" color="secondary" label={chipLabel} />
            )}
          </StyledTitleContainer>
        )}
        {[HorizontalCardType.Large, HorizontalCardType.NoCTA].includes(variant) &&
          (typeof content === 'string' ? (
            <StyledContentContainer>
              <div dangerouslySetInnerHTML={{ __html: content ?? '' }} />
            </StyledContentContainer>
          ) : (
            <StyledContentContainer>{content}</StyledContentContainer>
          ))}
        {handleCTA &&
          variant !== HorizontalCardType.NoCTA &&
          [HorizontalCardType.Large].includes(variant) && (
            <StyledBottomContainer>
              {buttonList && (
                <Box sx={{ marginRight: { xs: 0, sm: 6 } }}>
                  <ButtonsGroup buttonList={buttonList} nonClickable={true} />
                </Box>
              )}
              <StyledCTAButton
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleCTA(employeeId)}>
                Reserve Now
              </StyledCTAButton>
            </StyledBottomContainer>
          )}
      </StyledInnerRightCardContainer>
    </StyledHorizontalCardContainer>
  );
};
export default HorizontalCard;
