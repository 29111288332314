export enum AppointmentCardType {
  Large = 'large',
  Small = 'small',
  NoCTA = 'noCTA',
}

export const DAYS_OF_THE_WEEK = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export enum AppointmentTypes {
  INTRO = 'IntroLesson',
  GROUP = 'GroupLesson',
  PRIVATE = 'PrivateLesson',
  RENTAL = 'SimRental',
  PARTY_RENTAL = 'PartySimRental',
  CLASS = 'Class',
  CUSTOM = 'CustomLesson',
  BOWLING = 'BowlingRental',
  PARTY_BOWLING = 'PartyBowlingRental',
}

export interface IMyBooking {
  id: string;
  image: string;
  title: string;
  rawTime: Date;
  bookingDate: string;
  bookingIds: number[];
  startTime: string;
  location: string;
  instructor: string;
  sessionLength: number;
  price: number | null;
  type: AppointmentTypes;
  locationId: string;
  hasArrived: boolean;
  checkInAvailableStartDate: Date;
  checkInAvailableStartDateUTC: Date;
}
