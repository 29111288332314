import React, { FC, useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AppointmentTypes } from 'common';

import useCaseExternalNavigation from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateTo';
import { mockPartySizeButtons } from 'infrastructure/components/ButtonsGroup/stories/mockButtons';

interface IPartySizeSelect {
  selectedPartySize?: string;
  onChange?: (partySize: string) => void;
}
const PartySizeSelect: FC<IPartySizeSelect> = ({ selectedPartySize, onChange }) => {
  const [partySize, setPartySize] = useState('');
  const { navigateToContactPage } = useCaseExternalNavigation();

  const handleChange = (event: SelectChangeEvent) => {
    const partySizeValue = event.target.value as string;
    setPartySize(partySizeValue);
    if (onChange) {
      onChange(partySizeValue);
    }
  };

  useEffect(() => {
    if (partySize === '13+') {
      navigateToContactPage();
    }
  }, [partySize]);

  useEffect(() => {
    if (selectedPartySize) {
      setPartySize(selectedPartySize);
    }
  }, [selectedPartySize]);

  return (
    <FormControl fullWidth sx={{ minWidth: '100px', flex: 2 }}>
      <InputLabel id="party_size_select">Party Size</InputLabel>
      <Select
        labelId="party_size_select"
        sx={{
          '& .MuiSelect-icon': {
            display: 'block',
          },
        }}
        id="party_size_select"
        name="partySize"
        value={partySize}
        label="Party Size"
        color="secondary"
        onChange={handleChange}>
        {mockPartySizeButtons?.map((size) => (
          <MenuItem key={size.title} value={size.value}>
            {size.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default PartySizeSelect;
