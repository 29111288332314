import jwtDecode from 'jwt-decode';
import {
  BaseQueryApi,
  BaseQueryFn,
  QueryReturnValue,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import {
  createApi,
  EndpointDefinitions,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { Variants } from 'common';
import { REHYDRATE } from 'redux-persist';

import { RootState } from 'infrastructure/redux/store';
import { setAccessToken, setRefreshToken } from 'infrastructure/redux/slices/auth.slice';

import { AuthRoutes } from './mboApiRoutes';
import { clearUserData } from 'infrastructure/redux/slices/user.slice';
import { Mutex } from 'async-mutex';

const mutex = new Mutex();

const prepareHeaders = ({
  headers,
  token,
  variant,
}: {
  headers: Headers;
  token?: string;
  variant?: string;
}) => {
  headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  if (variant) {
    headers.set('X-Variant', variant);
  }

  return headers;
};

const resolveVariant = () => {
  if (process.env.VITE_IS_WEB) {
    return `${process.env.VITE_VARIANT || Variants.FIVEIRON}`;
  } else {
    return Variants.FIVEIRON_MOBILE;
  }
};

const logout = async (api: BaseQueryApi) => {
  api.dispatch(setAccessToken(null));
  api.dispatch(setRefreshToken(null));
  api.dispatch(clearUserData());
};

const refreshToken = async (api: BaseQueryApi): Promise<string | undefined> => {
  const storeState = api?.getState() as RootState;
  const baseUrl = getAPIBaseURL(storeState);
  const refreshToken = storeState.auth?.refreshToken as string;

  if (!refreshToken) {
    await logout(api);
    return;
  }

  const decodedToken: {
    aud: string;
    exp: number;
    iat: number;
    sub: string;
  } = jwtDecode(refreshToken);

  if (decodedToken.exp < Date.now() / 1000) {
    await logout(api);
    return;
  }

  const refreshTokenQuery = fetchBaseQuery({
    baseUrl,
  });

  const refreshResult = (await refreshTokenQuery(
    {
      url: AuthRoutes.refreshToken(),
      method: 'POST',
      body: {
        refreshToken: refreshToken,
      },
    },
    api,
    {},
  )) as QueryReturnValue<{ accessToken: string; refreshToken: string }>;

  if (refreshResult.error) {
    await logout(api);
    return;
  }

  if (refreshResult.data && storeState.auth.token) {
    api.dispatch(setRefreshToken(refreshResult.data.refreshToken));
    api.dispatch(setAccessToken(refreshResult.data.accessToken));
    return refreshResult.data.accessToken;
  } else {
    await logout(api);
  }
};

const getAPIBaseURL = (storeState: RootState) => {
  try {
    if (process.env.VITE_IS_WEB) {
      if (process.env.NODE_ENV === 'development') {
        return '/api/';
      }
      return process.env.VITE_BACKEND_API_BASE_URL;
    } else {
      return storeState?.auth?.apiBaseURL;
    }
  } catch (error) {
    return undefined;
  }
};

export const getDefaultFetchBaseQueryArgs: (
  baseUrl?: string,
  token?: string | undefined,
  variant?: string,
) => FetchBaseQueryArgs = (baseUrl, token, variant) => ({
  baseUrl,
  prepareHeaders: (headers: Headers): Headers =>
    prepareHeaders({
      headers,
      token,
      variant,
    }),
});

export const baseQueryWithReauth: (
  customFetchBaseQueryArgs?: FetchBaseQueryArgs,
) => BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
  (customFetchBaseQueryArgs) => async (args, api, extraOptions) => {
    const storeState = api.getState() as RootState;
    const baseUrl = getAPIBaseURL(storeState);
    const variant = resolveVariant();
    let accessToken = storeState?.auth?.accessToken;

    const isTokenExpired = (token: string) => {
      const { exp } = jwtDecode<{ exp: number }>(token);
      return exp < Date.now() / 1000;
    };

    const handleTokenRefresh = async () => {
      // https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#preventing-multiple-unauthorized-errors
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          return await refreshToken(api);
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        return (api.getState() as RootState)?.auth?.accessToken;
      }
    };

    if (accessToken && isTokenExpired(accessToken)) {
      accessToken = await handleTokenRefresh();
    }

    const baseQueryFn = fetchBaseQuery(
      customFetchBaseQueryArgs || getDefaultFetchBaseQueryArgs(baseUrl, accessToken, variant),
    );

    let result = await baseQueryFn(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
      accessToken = await handleTokenRefresh();

      const secondAttemptQuery = fetchBaseQuery(
        customFetchBaseQueryArgs || getDefaultFetchBaseQueryArgs(baseUrl, accessToken, variant),
      );

      result = await secondAttemptQuery(args, api, extraOptions);
    }

    return result;
  };

export const baseApi = createApi({
  reducerPath: 'mboAPI',
  keepUnusedDataFor: 60,
  baseQuery: baseQueryWithReauth(),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.payload && action.type === REHYDRATE) {
      return action.payload[reducerPath];
    }
  },
  tagTypes: [
    'User',
    'SimulatorAppointments',
    'SimulatorAppointmentsDates',
    'LessonAppointments',
    'FirstLessonAppointments',
    'LessonAppointmentsDates',
    'AppointmentPricing',
  ],

  endpoints: (): EndpointDefinitions => ({} as EndpointDefinitions),
});

export default baseApi;
