import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { BookingWizardFlowTypes } from 'common/infrastructure/modules/appointment/interfaces/AppointmentTypes';
import { addDays, endOfDay, format, isPast, isToday, startOfDay } from 'date-fns';
import { useEffect, useMemo } from 'react';

import {
  FirstTimeLessonError,
  GetInstructorsError,
  IGetInstructorsAdapter,
} from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectInstructor';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
import {
  selectExperienceType,
  selectLocation,
  selectReservationDate,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import {
  setExperienceType,
  setInstructor,
  setInstructorDetails,
  setPartySize,
} from 'infrastructure/redux/slices/bookingWizard.slice';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import { isValidDateString } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/helpers';
function getFetchInstructorsError(
  error: FetchBaseQueryError | SerializedError | undefined,
): GetInstructorsError | undefined {
  if (!error) {
    return undefined;
  }
  return GetInstructorsError.Unknown;
}

function getFirstTimeLessonError(
  error: FetchBaseQueryError | SerializedError | undefined,
): FirstTimeLessonError | undefined {
  if (!error) {
    return undefined;
  }
  return FirstTimeLessonError.Unknown;
}

const useGetInstructors: IGetInstructorsAdapter = () => {
  const dispatch = useAppDispatch();
  const selectedLocationId = useAppSelector(selectLocation);
  const reservationDate = useAppSelector(selectReservationDate);
  const flowType = useAppSelector(selectExperienceType);
  const isReservationDateToday = reservationDate && isToday(new Date(reservationDate));
  const startDate =
    reservationDate &&
    (isReservationDateToday ? new Date(reservationDate) : startOfDay(new Date(reservationDate)));
  const endDate = reservationDate && endOfDay(new Date(reservationDate));
  const { email } = useAppSelector(selectUserSummary);

  const isDateValid = useMemo(() => {
    if (!reservationDate || Number.isNaN(new Date(reservationDate).getTime())) return;

    return isValidDateString(reservationDate) && !isPast(addDays(new Date(reservationDate), 1));
  }, [reservationDate]);

  const [
    getFirstTimeLessonAppointmentAvailabilities,
    {
      data: firstTimeLessonAppointmentAvailabilities,
      isLoading: areFirstTimeLessonLoading,
      error: firstTimeLessonError,
      isFetching: areFirstTimeLessonFetching,
    },
  ] = mboAPI.useLazyGetAvailableFirstTimeLessonsAppointmentsQuery();

  const [
    getInstructors,
    {
      data: instructors,
      isLoading: areInstructorsLoading,
      error: instructorsError,
      isFetching: areInstructorsFetching,
    },
  ] = mboAPI.useLazyGetAvailableLessonAppointmentsQuery();

  const setFlowTypeToLesson = () => {
    dispatch(setExperienceType({ experienceType: BookingWizardFlowTypes.Lesson }));
  };

  const clearPartySize = () => {
    dispatch(setPartySize({ partySize: undefined }));
  };

  const selectFirstLessonFlow = () => {
    dispatch(setExperienceType({ experienceType: BookingWizardFlowTypes.FirstLesson }));
    dispatch(setInstructor({ instructor: undefined }));
    dispatch(setInstructorDetails(undefined));
  };

  const saveSelectedInstructorToStore = (staffId: number) => {
    dispatch(setInstructor({ instructor: staffId }));
    const instructorDetails = instructors?.find((instructor) => instructor.staffId === staffId);
    dispatch(setInstructorDetails({ ...instructorDetails }));
  };

  useEffect(() => {
    if (selectedLocationId && isDateValid) {
      getFirstTimeLessonAppointmentAvailabilities(
        {
          locationId: selectedLocationId,
          startDateTime: startDate && isDateValid ? format(startDate, 'yyyy-MM-dd') : undefined,
          endDateTime: endDate && isDateValid ? format(endDate, 'yyyy-MM-dd') : undefined,
          email,
        },
        {
          refetchOnMountOrArgChange: true,
        },
      );

      getInstructors(
        {
          locationId: selectedLocationId,
          startDateTime: startDate && isDateValid ? format(startDate, 'yyyy-MM-dd') : undefined,
          endDateTime: endDate && isDateValid ? format(endDate, 'yyyy-MM-dd') : undefined,
          email,
        },
        {
          refetchOnMountOrArgChange: true,
        },
      );
    }
  }, [selectedLocationId, startDate?.toString(), endDate?.toString(), isDateValid, email]);

  return {
    firstTimeLessons: firstTimeLessonAppointmentAvailabilities,
    firstTimeLessonError: getFirstTimeLessonError(firstTimeLessonError),
    firstTimeLessonsLoading: areFirstTimeLessonLoading || areFirstTimeLessonFetching,
    result: instructors,
    inProgress: areInstructorsLoading || areInstructorsFetching,
    error: getFetchInstructorsError(instructorsError),
    saveSelectedInstructorToStore,
    setFlowTypeToLesson,
    selectFirstLessonFlow,
    clearPartySize,
    getFirstTimeLessonAppointmentAvailabilities,
    getInstructors,
    flowType,
  };
};

export default useGetInstructors;
