import { useInjection } from 'inversify-react';
import { interfaces } from 'inversify';

import ServiceIdentifier = interfaces.ServiceIdentifier;

export type AsyncHookResult<R = void, E = string> = {
  result?: R;
  inProgress: boolean;
  error?: E;
};
export type InjectableHook<T extends {}> = () => T;

export function useHookInjection<T extends InjectableHook<any>>(
  identifier: ServiceIdentifier<T>,
): ReturnType<T> {
  return useInjection<T>(identifier)();
}
