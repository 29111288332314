import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Country } from 'domain/entities/Country';

export enum ProviderName {
  Google = 'google',
  Apple = 'apple',
}

export interface ProviderUserInfo {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
}

export interface AuthState {
  token?: string;
  accessToken?: string;
  refreshToken?: string;
  showError?: boolean;
  selectedCountry?: Country;
  apiBaseURL?: string;
  errorStatus?: number;
  providerName?: ProviderName;
  providerUserInfo?: ProviderUserInfo;
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {},
  reducers: {
    setToken: (state: AuthState, action: PayloadAction<string>) => ({
      ...state,
      token: action.payload,
    }),
    setAccessToken: (state: AuthState, action: PayloadAction<string | null>) => ({
      ...state,
      accessToken: action.payload,
    }),
    setRefreshToken: (state: AuthState, action: PayloadAction<string | null>) => ({
      ...state,
      refreshToken: action.payload,
    }),
    setShowError: (state: AuthState, action: PayloadAction<boolean>) => ({
      ...state,
      showError: action.payload,
    }),
    setErrorStatus: (state: AuthState, action: PayloadAction<boolean>) => ({
      ...state,
      errorStatus: action.payload,
    }),
    clearAuthData: () => ({}),
    setSelectedCountry: (state: AuthState, action: PayloadAction<Country>) => ({
      ...state,
      selectedCountry: action.payload,
    }),
    setAPIbaseURL: (state: AuthState, action: PayloadAction<string>) => ({
      ...state,
      apiBaseURL: action.payload,
    }),
    setProviderName: (state: AuthState, action: PayloadAction<ProviderName>) => ({
      ...state,
      providerName: action.payload,
    }),
    setProviderUserInfo: (state: AuthState, action: PayloadAction<ProviderUserInfo>) => ({
      ...state,
      providerUserInfo: action.payload,
    }),
  },
});

export const {
  setToken,
  setAccessToken,
  clearAuthData,
  setRefreshToken,
  setShowError,
  setErrorStatus,
  setSelectedCountry,
  setAPIbaseURL,
  setProviderName,
  setProviderUserInfo,
} = authSlice.actions;
export default authSlice.reducer;
