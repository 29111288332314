import { Button, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import useCaseCheckYourEmail from 'application/modules/auth/useCases/hooks/useCaseCheckYourEmail';
import { UserVerificationError } from 'application/modules/auth/useCases/hooks/useCaseVerifyUser';
import { WizardStep } from 'domain/entities/WizardStep';
import ContentCard from 'infrastructure/components/ContentCard';
import NavigationBar from 'infrastructure/components/NavigationBar';
import PageLayout from 'infrastructure/components/PageLayout';
import { selectEmail, selectLocation } from 'infrastructure/redux/slices/bookingWizard.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import { StyledSection500Column } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SignInStep/style';

interface ICheckYourEmailStep {
  stepName: WizardStep;
}

const CheckYourEmailStep: FC<ICheckYourEmailStep> = ({ stepName }) => {
  const theme = useTheme();
  const routerLocation = useLocation();
  const locationState = routerLocation.state;
  const userValidationError = locationState?.error;
  const selectedEmail = useAppSelector(selectEmail);
  const selectedLocationId = useAppSelector(selectLocation);
  const { openMailbox, resendEmail, prevStep, getEmailProvider, counter } =
    useCaseCheckYourEmail(stepName);
  const emailProvider =
    (selectedEmail && getEmailProvider(selectedEmail)?.providerName) || 'Mailbox';
  return (
    <PageLayout pageTitle={'Check Out'} shouldFadeIn>
      <NavigationBar backButtonCallback={prevStep} />
      <StyledSection500Column>
        <ContentCard title={'Check your email'}>
          <Typography variant={'body1'} sx={{ mt: 3, mb: 7 }}>
            We’ve sent you an email with a magic link to sign you in. Please follow the instructions
            in your email to finish making your reservation.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className="nextButton"
            size="large"
            sx={{ mb: 4 }}
            onClick={() => selectedEmail && openMailbox(selectedEmail)}>
            Open {emailProvider}
          </Button>
          <Typography
            className="belowNextButton"
            variant={'body1'}
            sx={{ mt: 5, width: '100%', textAlign: 'center' }}>
            Didn’t receive an email?{' '}
            <Link to="" style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
              {counter === 0 ? (
                <strong
                  onClick={() =>
                    selectedEmail &&
                    selectedLocationId &&
                    resendEmail(selectedEmail, selectedLocationId)
                  }>
                  Resend
                </strong>
              ) : (
                `${counter} sec`
              )}
            </Link>
          </Typography>
        </ContentCard>

        {userValidationError && userValidationError === UserVerificationError.ValidationError && (
          <Typography
            variant={'body2'}
            color={'text.secondary'}
            sx={{ mt: 3, mb: 7, textAlign: 'center' }}>
            It seems that there was a problem with validating your email, please try again with
            another email or contact our support.
          </Typography>
        )}

        {userValidationError && userValidationError !== UserVerificationError.ValidationError && (
          <Typography
            variant={'body2'}
            color={'text.secondary'}
            sx={{ mt: 3, mb: 7, textAlign: 'center' }}>
            It seems that there was a problem with validating your request, please try again or
            contact our support.
          </Typography>
        )}
      </StyledSection500Column>
    </PageLayout>
  );
};

export default CheckYourEmailStep;
