import { Variants } from 'common';

import { ReactComponent as MBOLogo } from 'infrastructure/targets/web/assets/icons/MindBodyLogo.svg';
import { ReactComponent as MBOLogoLight } from 'infrastructure/targets/web/assets/icons/mindbody-logo-light.svg';
import { isAustralia } from './helpers';

const resolveImageVariant = () => process.env.VITE_VARIANT || Variants.FIVEIRON;

export const getLogoLink = () => {
  try {
    if (isAustralia()) {
      return 'https://fiveirongolf.au/';
    }

    switch (resolveImageVariant()) {
      case Variants.FIVEIRON:
        return 'https://fiveirongolf.com/';

      case Variants.DUCKPIN:
        return isGenericDuckpin()
          ? 'https://duckpin.fiveirongolf.com'
          : 'https://detroitduckpin.com';

      default:
        return 'https://fiveirongolf.com/';
    }
  } catch (error) {
    return 'https://fiveirongolf.com/';
  }
};

export const getInstructorPlaceholderImageUrl = () => {
  try {
    return new URL('infrastructure/targets/web/assets/icons/placeholderImage.png', import.meta.url)
      .href;
  } catch (error) {
    return '';
  }
};

export const getTopBannerPictureUrl = () => {
  try {
    switch (resolveImageVariant()) {
      case Variants.FIVEIRON:
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/topBanner.jpg`,
          import.meta.url,
        ).href;
      case Variants.DUCKPIN:
        return new URL(
          'infrastructure/targets/web/assets/images/duckpin/topBanner.jpg',
          import.meta.url,
        ).href;
      default:
        return new URL(
          'infrastructure/targets/web/assets/images/fiveiron/topBanner.jpg',
          import.meta.url,
        ).href;
    }
  } catch (error) {
    return '';
  }
};

export const getSimThumbnail = () => {
  try {
    switch (resolveImageVariant()) {
      case Variants.FIVEIRON:
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/simThumbnail.jpg`,
          import.meta.url,
        ).href;
      case Variants.DUCKPIN:
        return new URL(
          'infrastructure/targets/web/assets/images/duckpin/simThumbnail.jpg',
          import.meta.url,
        ).href;
      default:
        return new URL(
          'infrastructure/targets/web/assets/images/fiveiron/simThumbnail.jpg',
          import.meta.url,
        ).href;
    }
  } catch (error) {
    return '';
  }
};

export const getBookingImage = () => {
  try {
    switch (resolveImageVariant()) {
      case Variants.FIVEIRON:
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/simBooking.jpg`,
          import.meta.url,
        ).href;
      case Variants.DUCKPIN:
        return new URL(
          'infrastructure/targets/web/assets/images/duckpin/simBooking.jpg',
          import.meta.url,
        ).href;
      default:
        return new URL(
          'infrastructure/targets/web/assets/images/fiveiron/simBooking.jpg',
          import.meta.url,
        ).href;
    }
  } catch (error) {
    return '';
  }
};

export const getSimBookingImage = () =>
  new URL(`infrastructure/targets/web/assets/images/fiveiron/simBooking.jpg`, import.meta.url).href;

export const getBowlingBookingImage = () =>
  new URL('infrastructure/targets/web/assets/images/duckpin/simBooking.jpg', import.meta.url).href;

export const getLessonBookingImage = () => {
  try {
    switch (resolveImageVariant()) {
      case Variants.FIVEIRON:
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/lessonBooking.jpg`,
          import.meta.url,
        ).href;
      case Variants.DUCKPIN:
        return new URL(
          'infrastructure/targets/web/assets/images/duckpin/lessonBooking.jpg',
          import.meta.url,
        ).href;
      default:
        return new URL(
          'infrastructure/targets/web/assets/images/fiveiron/lessonBooking.jpg',
          import.meta.url,
        ).href;
    }
  } catch (error) {
    return '';
  }
};

export const isGenericDuckpin = () => {
  const hostname = window.location.hostname;
  return hostname.includes('dp.stage.booking') || hostname.includes('duckpin.fiveirongolf');
};

export const getMainLogo = () => {
  const duckpinGeneric = isGenericDuckpin();
  try {
    switch (resolveImageVariant()) {
      case Variants.FIVEIRON:
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/logo.png`,
          import.meta.url,
        ).href;
      case Variants.DUCKPIN:
        return duckpinGeneric
          ? new URL(`infrastructure/targets/web/assets/images/fiveiron/logo.png`, import.meta.url)
              .href
          : new URL('infrastructure/targets/web/assets/images/duckpin/logo.png', import.meta.url)
              .href;
      default:
        return new URL(
          'infrastructure/targets/web/assets/images/fiveiron/logo.png',
          import.meta.url,
        ).href;
    }
  } catch (error) {
    return '';
  }
};

export const getMindBodyLogo = () => {
  try {
    switch (resolveImageVariant()) {
      case Variants.FIVEIRON:
        return MBOLogo;
      case Variants.DUCKPIN:
        return MBOLogoLight;
      default:
        return MBOLogo;
    }
  } catch (error) {
    return MBOLogo;
  }
};

export const getMobileLogo = () => {
  try {
    switch (resolveImageVariant()) {
      case Variants.FIVEIRON:
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/logo.png`,
          import.meta.url,
        ).href;
      case Variants.DUCKPIN:
        return new URL(
          'infrastructure/targets/web/assets/images/duckpin/logoMobile.png',
          import.meta.url,
        ).href;
      default:
        return new URL(
          'infrastructure/targets/web/assets/images/fiveiron/logo.png',
          import.meta.url,
        ).href;
    }
  } catch (error) {
    return '';
  }
};
