import { add, endOfMonth, startOfToday } from 'date-fns';
import { useMemo, useState } from 'react';

import useCaseNavigateBookingWizard from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateBookingWizard';
import useCaseExternalNavigation from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateTo';
import { IGetSimulatorAvailabilityAdapter } from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectSimulatorReservationDate';
import { BookingWizardFlowTypes } from 'common/dist/infrastructure/modules/appointment/interfaces/AppointmentTypes';
import { WizardStep } from 'domain/entities/WizardStep';
import {
  selectExperienceType,
  selectLocation,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { setResourceId } from 'infrastructure/redux/slices/bookingWizard.slice';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';

import mboAPI from '../mboAPI/mboApi';
const useGetSimulatorAvailability: IGetSimulatorAvailabilityAdapter = () => {
  const selectedLocationId = useAppSelector(selectLocation);
  const startDateTime = useMemo(() => startOfToday().toISOString(), []);
  const flowType = useAppSelector(selectExperienceType);
  const dispatch = useAppDispatch();
  const isSimulatorFlow =
    flowType === BookingWizardFlowTypes.Simulator || flowType === BookingWizardFlowTypes.Bowling;

  const [endDateTime, setEndDateTime] = useState(
    endOfMonth(add(new Date(), { months: 1 })).toISOString(),
  );
  const { nextStep } = useCaseNavigateBookingWizard(WizardStep.reservationDate);
  const { navigateToContactPage } = useCaseExternalNavigation();
  const {
    data: availableDatesMap,
    error,
    isLoading,
    isFetching,
  } = mboAPI.useGetAvailableSimulatorRentalDatesQuery(
    {
      locationId: selectedLocationId || '',
      startDateTime,
      endDateTime,
    },
    { skip: !selectedLocationId || !startDateTime || !endDateTime || !isSimulatorFlow },
  );
  const availableDates = useMemo(
    () => availableDatesMap?.map((date) => new Date(date)),
    [availableDatesMap],
  );

  const handleNextStep = (partySize: string) => {
    dispatch(setResourceId({ undefined }));
    if (partySize === '13+') {
      navigateToContactPage();
    } else {
      nextStep();
    }
  };

  const handleFetchFurtherAvailableDates = (newDate: Date | null) => {
    if (newDate) {
      setEndDateTime(endOfMonth(add(newDate, { months: 1 })).toISOString());
    }
  };

  return {
    inProgress: isLoading || isFetching,
    error,
    result: availableDates,
    fetchFurtherAvailableDates: handleFetchFurtherAvailableDates,
    nextStep: handleNextStep,
  };
};

export default useGetSimulatorAvailability;
