import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { LocationTypes } from 'common';

import useCaseGetSimulatorLocations from 'application/modules/bookingWizard/useCases/hooks/useCaseGetSimulatorLocations';
import { setLocation, setLocationDetails } from 'infrastructure/redux/slices/bookingWizard.slice';
import { useAppDispatch } from 'infrastructure/redux/store/hooks';

import { StyledTextContainer, StyledValueContainer } from './style';

interface ILocationSelect {
  onChange?: (event: SelectChangeEvent<string>, value: LocationTypes.SimulatorLocation) => void;
  selectedSimLocationId?: string;
  fullWidth?: boolean;
  label?: string;
}

const LocationSelect: FC<ILocationSelect> = ({
  onChange,
  selectedSimLocationId,
  fullWidth,
  label,
}) => {
  const dispatch = useAppDispatch();
  const {
    result: locations,
    inProgress: areLocationsFetching,
    error: locationsError,
    refetchUserDataWhenLocationIdChanged,
  } = useCaseGetSimulatorLocations();

  const [simLocation, setSimLocation] = useState(
    locations?.find((location) => location?.id === selectedSimLocationId),
  );

  useEffect(() => {
    if (selectedSimLocationId) {
      setSimLocation(locations?.find((location) => location?.id === selectedSimLocationId));
    }
  }, [selectedSimLocationId, locations]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    const selectedSimLocation = locations?.find((location) => location.id === value);
    refetchUserDataWhenLocationIdChanged(value);
    if (selectedSimLocation) {
      setSimLocation(selectedSimLocation);
      dispatch(setLocation(value));
      dispatch(setLocationDetails(selectedSimLocation));
    }

    if (onChange && selectedSimLocation) {
      onChange(event, selectedSimLocation);
    }
  };

  const renderSelectedValue = (selected: string) => {
    const selectedLocation = locations?.find((location) => location.id === selected);
    if (!locations || locations?.length === 0) {
      return (
        <StyledValueContainer>
          <PlaceOutlinedIcon style={{ marginRight: '10px' }} />
          <StyledTextContainer>
            {areLocationsFetching && !locationsError
              ? 'Fetching locations...'
              : 'No locations found'}
          </StyledTextContainer>
        </StyledValueContainer>
      );
    }
    return (
      <StyledValueContainer>
        <PlaceOutlinedIcon style={{ marginRight: '10px' }} />
        <StyledTextContainer>{selectedLocation?.name}</StyledTextContainer>
      </StyledValueContainer>
    );
  };

  const getLocationLabel = () => {
    if (label) {
      return label;
    }
    if (areLocationsFetching && !locationsError) {
      return 'Fetching locations';
    }
    if (!locations) {
      return 'No locations found';
    }
    if (locationsError) {
      return 'Error occurred while fetching locations';
    }
    return 'Locations';
  };
  return (
    <FormControl fullWidth sx={{ width: { xs: '100%', md: fullWidth ? '100%' : '350px' } }}>
      <InputLabel id="location">{getLocationLabel()}</InputLabel>
      <Select
        labelId="location"
        name="location"
        id="location-select"
        value={simLocation?.id || ''}
        onChange={handleChange}
        label={getLocationLabel()}
        renderValue={renderSelectedValue}
        color="secondary">
        {!locations && <MenuItem value={''}>No locations found</MenuItem>}
        {locations?.map((location) => (
          <MenuItem key={location.id} value={location.id}>
            {location.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LocationSelect;
