import { InfoOutlined } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Close';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { Variants } from 'common';
import { differenceInHours } from 'date-fns';
import React, { useContext, useState } from 'react';

import { AppointmentTypes } from 'domain/entities/ManageBooking';
import { TextCopyContext } from 'infrastructure/targets/web/contexts/TextCopyContext';
import { formatToUSDollars } from 'infrastructure/targets/web/modules/common/helpers';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';

const IMAGE_SIZE = 180;

interface BookingDetails {
  locationId: string;
  image: string;
  title: string;
  bookingDate: string;
  rawTime: Date;
  location: string;
  instructor: string;
  startTime: string;
  sessionLength: number;
  price: number | null;
  bookingIds: number[];
  type: string;
}

interface BookingCardProps {
  bookingDetails: BookingDetails;
  onCancel?: (id: number[], locId: string) => void;
  onGetPhoneNumber: (locationId: string) => string;
}

const BookingCard: React.FC<BookingCardProps> = ({
  bookingDetails,
  onCancel,
  onGetPhoneNumber,
}) => {
  const { getTextCopy } = useContext(TextCopyContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const user = useAppSelector(selectUserSummary);
  const {
    locationId,
    image,
    title,
    rawTime,
    bookingDate,
    location,
    instructor,
    startTime,
    sessionLength,
    price,
    type,
  } = bookingDetails;

  const handleCardClick = () => {
    // TODO - add booking details redirect
  };

  const isRental = () =>
    type === AppointmentTypes.RENTAL ||
    type === AppointmentTypes.PARTY_RENTAL ||
    type === AppointmentTypes.BOWLING ||
    type === AppointmentTypes.PARTY_BOWLING;
  const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleOnCancelClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setOpen(false);
    onCancel && onCancel(bookingDetails.bookingIds, locationId);
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        border: `1px solid ${theme.palette.other.outlineBorder}`,
        p: 4,
        pb: 0,
        cursor: 'pointer',
        mb: 5,
        boxShadow: 'none',
        minHeight: isMobile ? 'auto' : IMAGE_SIZE + 32,
      }}
      onClick={handleCardClick}
      elevation={0}>
      {!isMobile ? (
        <CardMedia
          component="img"
          sx={{
            width: isMobile ? '100%' : IMAGE_SIZE,
            height: IMAGE_SIZE,
            borderRadius: 1,
            objectFit: 'cover',
          }}
          image={image}
          alt={title}
        />
      ) : (
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <CardMedia
            component="img"
            sx={{
              width: IMAGE_SIZE / 3,
              height: IMAGE_SIZE / 3,
              borderRadius: 1,
              objectFit: 'cover',
            }}
            image={image}
            alt={title}
          />
          <Box ml={4}>
            <Typography component="h5" variant="h6" fontWeight={900}>
              {title}
            </Typography>
            <Typography
              sx={{
                color:
                  process.env.VITE_VARIANT === Variants.DUCKPIN
                    ? `${theme.palette.success.main}!important`
                    : `text.primary!important`,
              }}
              fontWeight={'bold'}>
              {bookingDate}
            </Typography>
          </Box>
        </Box>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <CardContent
          sx={{ flex: 1, pt: isMobile ? 2 : 0, pb: '8px!important', pl: isMobile ? 2 : 4, pr: 0 }}>
          <Grid container spacing={2} height={'100%'}>
            <Grid item xs={isMobile ? 12 : 8}>
              {!isMobile && (
                <>
                  <Typography component="h5" variant="h5" fontWeight={900}>
                    {title}
                  </Typography>
                  <Typography
                    variant={'subtitle1'}
                    sx={{
                      color:
                        process.env.VITE_VARIANT === Variants.DUCKPIN
                          ? `${theme.palette.success.main}!important`
                          : `${theme.palette.primary.main}!important`,
                    }}
                    fontWeight={'bold'}>
                    {bookingDate}
                  </Typography>
                </>
              )}
              <Typography variant={'subtitle1'}>Location: {location}</Typography>
              {instructor && !isRental() && (
                <Typography variant={'subtitle1'}>Instructor: {instructor}</Typography>
              )}

              {!isRental() && (
                <Typography variant={'subtitle1'}>
                  {getTextCopy('lessonType')}: {sessionLength}{' '}
                  {type === AppointmentTypes.GROUP
                    ? 'Hour (Small Group)'
                    : sessionLength > 1
                    ? 'Hours'
                    : 'Hour'}
                </Typography>
              )}
              <Typography variant={'subtitle1'}>Start Time: {startTime}</Typography>
              {isRental() && (
                <Typography variant={'subtitle1'}>
                  Session Length: {sessionLength} {sessionLength > 1 ? 'Hours' : 'Hour'}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={isMobile ? 12 : 4}
              container
              direction={'column'}
              justifyContent={'space-between'}>
              {!price ? (
                <Box textAlign={isMobile ? 'start' : 'end'} mb={isMobile ? 2 : 0}>
                  <Tooltip
                    enterTouchDelay={0}
                    title="Pricing information unavailable for bookings made through the MBO app or over the phone. Please contact us for more information.">
                    <Button
                      size="small"
                      sx={{
                        pl: 0,
                        pr: 0,
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: 'transparent',
                        },
                        fontSize: '16px',
                      }}>
                      Pricing Unavailable
                      <InfoOutlined
                        fontSize="small"
                        sx={{ ml: '3px', color: theme.palette.text.disabled }}
                      />
                    </Button>
                  </Tooltip>
                </Box>
              ) : (
                <Typography
                  variant={'h5'}
                  textAlign={isMobile ? 'start' : 'end'}
                  mb={isMobile ? 2 : 0}
                  fontWeight={900}>{`${formatToUSDollars(Number(price))}`}</Typography>
              )}
              <Button
                sx={{ width: isMobile ? '100%' : 180, alignSelf: 'flex-end' }}
                startIcon={<CancelIcon color={'error'} />}
                variant={'outlined'}
                color={'error'}
                onClick={handleCancelClick}>
                Cancel Booking
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Box>
      {differenceInHours(rawTime, new Date()) < 12 && !user.member ? (
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Late Cancellation</DialogTitle>
          <DialogContent>
            You may be charged a $10 cancellation fee if you cancel now.
          </DialogContent>
          <DialogActions sx={{ p: 4 }}>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={handleOnCancelClick} color={'error'} variant={'contained'}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Are you sure you want to cancel this booking?</DialogTitle>
          <DialogActions sx={{ p: 4 }}>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={handleOnCancelClick} color={'error'} variant={'contained'}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Card>
  );
};

export default BookingCard;
