export const LocationRoutes = {
  getLocations: (siteId?: number) => {
    if (siteId) {
      return `locations?siteId=${siteId}`;
    }
    return `locations`;
  },
  getLocationPricingSchedule: (locationId: string, dayOfMonthIndex: number) =>
    `locations/${locationId}/pricing-schedule/${dayOfMonthIndex}`,
  getLocationEventsCalendar: (locationId: string) => `calendar/events/${locationId}`,
};

export const AppointmentRoutes = {
  getAvailableSimulatorRentalAppointments: (
    locationId: string,
    partySize: string,
    startDateTime?: string,
    endDateTime?: string,
    email?: string,
  ) => {
    let baseUrl = `appointments/available/simulator/?locationId=${locationId}&partySize=${partySize}`;
    if (startDateTime && endDateTime) {
      baseUrl = `${baseUrl}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
    }
    if (email) {
      const encodedEmail = encodeURIComponent(email);
      baseUrl = `${baseUrl}&email=${encodedEmail}`;
    }
    return baseUrl;
  },
  getAvailableSimulatorRentalDates: (
    locationId: string,
    startDateTime?: string,
    endDateTime?: string,
  ) => {
    if (startDateTime && endDateTime) {
      return `appointments/available/dates/simulator/?locationId=${locationId}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
    }
    return `appointments/available/dates/simulator/?locationId=${locationId}`;
  },
  getAvailableLessonAppointments: (
    locationId: string,
    startDateTime?: string,
    endDateTime?: string,
    staffIds?: number,
    email?: string,
  ) => {
    let baseUrl = `appointments/available/lesson/?locationId=${locationId}`;
    if (startDateTime && endDateTime) {
      baseUrl = `${baseUrl}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
    }
    if (staffIds) {
      baseUrl = `${baseUrl}&staffIds[]=${staffIds}`;
    }
    if (email) {
      const encodedEmail = encodeURIComponent(email);
      baseUrl = `${baseUrl}&email=${encodedEmail}`;
    }
    return baseUrl;
  },
  getAvailableFirstLessonAppointments: (
    locationId: string,
    startDateTime?: string,
    endDateTime?: string,
    email?: string,
  ) => {
    let baseUrl = `appointments/available/first-time-lesson/?locationId=${locationId}`;
    if (startDateTime && endDateTime) {
      baseUrl = `${baseUrl}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
    }
    if (email) {
      const encodedEmail = encodeURIComponent(email);
      baseUrl = `${baseUrl}&email=${encodedEmail}`;
    }
    return baseUrl;
  },
  getAvailableLessonDates: (locationId: string, startDateTime?: string, endDateTime?: string) => {
    if (startDateTime && endDateTime) {
      return `appointments/available/dates/lesson/?locationId=${locationId}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
    }
    return `appointments/available/dates/lesson/?locationId=${locationId}`;
  },
  getAppointmentPricing: () => `appointments/pricing`,
  getHourlySimulatorPricing: () => `appointments/simulator/hourly-pricing`,
  bookAppointment: (locationId: string) => `appointments/book/${locationId}`,
  verifyPromoCode: (siteId: string, promoCode: string, date: string) =>
    `appointments/promo/verify?siteId=${siteId}&date=${date}&promocode=${promoCode}`,
  getDailyPromoCode: (siteId: string, date: string) =>
    `appointments/daily-promos/${siteId}?date=${date}`,
  getClientAppointments: () => `appointments/me`,
  getUpcomingAppointments: () => `appointments/upcoming`,
  cancelAppointment: (locationId: string) => `appointments/cancel/${locationId}`,
  storeBookingData: () => `appointments/booking`,
  retrieveBookingData: (bookingUUID: string) => `appointments/booking/${bookingUUID}`,
  getAllInstructors: (locationId: string) => `appointments/instructors?locationId=${locationId}`,
  sendWaiverLinksToGuests: (locationId: string) => `appointments/book/${locationId}/waiver`,
  checkIn: (locationId: string) => `appointments/check-in/${locationId}`,
};

export const AuthRoutes = {
  welcomeScreen: () => `welcome`,
  loginWithEmail: () => `auth/login`,
  loginWithProvider: () => `auth/provider/login`,
  registerPushToken: () => `auth/user/push-token`,
  createAccount: () => `auth/register`,
  refreshToken: () => `auth/refresh`,
  deleteAccount: () => `auth/user`,
  getUserData: (locationId?: string) =>
    locationId ? `auth/user?locationId=${locationId}` : `auth/user`,
  updateUserData: () => `auth/user`,
  verifyUser: (token: string, email: string, redirectToUrl: string) =>
    `auth/verify?token=${token}&email=${email}&redirectTo=${redirectToUrl}`,
  updateBilling: (locationId: string) => `auth/update-billing?locationId=${locationId}`,
};
