import { useMemo, useState } from 'react';
import { add, endOfMonth, startOfToday } from 'date-fns';

import { IGetLessonAvailabilityAdapter } from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectLessonReservationDate';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import { selectLocation } from 'infrastructure/redux/slices/bookingWizard.selector';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
const useGetLessonAvailability: IGetLessonAvailabilityAdapter = () => {
  const selectedLocationId = useAppSelector(selectLocation);
  const startDateTime = useMemo(() => startOfToday().toISOString(), []);
  const [endDateTime, setEndDateTime] = useState(
    endOfMonth(add(new Date(), { months: 1 })).toISOString(),
  );

  const {
    data: availableDatesMap,
    error,
    isLoading,
    isFetching,
  } = mboAPI.useGetAvailableLessonDatesQuery(
    {
      locationId: selectedLocationId || '',
      startDateTime,
      endDateTime,
    },
    {
      skip: !selectedLocationId || !startDateTime || !endDateTime,
    },
  );
  const availableDates = useMemo(
    () => availableDatesMap?.map((date) => new Date(date)),
    [availableDatesMap],
  );

  const handleFetchFurtherAvailableDates = (newDate: Date | null) => {
    if (newDate) {
      setEndDateTime(endOfMonth(add(newDate, { months: 1 })).toISOString());
    }
  };

  return {
    inProgress: isLoading || isFetching,
    error,
    result: availableDates,
    fetchFurtherAvailableDates: handleFetchFurtherAvailableDates,
  };
};

export default useGetLessonAvailability;
