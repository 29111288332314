import { PersonOutlined } from '@mui/icons-material';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { FC, useState } from 'react';
interface IEmailLogoutSelect {
  email?: string;
  onLogout?: () => void;
  handleMyBookingsRedirect?: () => void;
}
const EmailLogoutSelect: FC<IEmailLogoutSelect> = ({
  email,
  onLogout,
  handleMyBookingsRedirect,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleLogout = () => {
    onLogout && onLogout();
  };

  const handleManageBookingsRedirect = () => {
    handleMyBookingsRedirect && handleMyBookingsRedirect();
  };

  return (
    <FormControl
      sx={{
        minWidth: isMobile ? 0 : 120,
        ml: 'auto',
        mt: isMobile ? 0 : 'auto',
        display: 'flex',
      }}>
      <Button onClick={handleOpen} sx={{ padding: 0, mr: -4 }}>
        <Box sx={{ display: 'flex', alignItems: 'right', color: 'white', mr: 0, pr: 0 }}>
          <PersonOutlined
            sx={{ fontSize: '23px', marginRight: isMobile ? 0 : 2, color: 'white', pr: 0 }}
          />
          {!isMobile && 'My Account'}
        </Box>
      </Button>
      {
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          sx={{ position: 'absolute', width: '100%', zIndex: '-1', visibility: 'hidden' }}>
          <MenuItem disabled>{email}</MenuItem>
          <MenuItem onClick={handleManageBookingsRedirect}>Manage Bookings</MenuItem>
          <MenuItem onClick={handleLogout}>Log Out</MenuItem>
        </Select>
      }
    </FormControl>
  );
};

export default EmailLogoutSelect;
