import { Box, Typography } from '@mui/material';
import React, { FC, MouseEvent, ReactNode, useEffect, useState } from 'react';

import { formatToUSDollars } from 'infrastructure/targets/web/modules/common/helpers';
import { StyledToggleButtonGroup } from './components/ExtendedToggleButtonGroup/style';
import { StyledButtonsGroupContainer, StyledButtonSubtitle, StyledToggleButton } from './style';

export interface IButtonGroupButton {
  title: ReactNode;
  subtitle?: ReactNode;
  value: string | number;
  additionalValue?: string | number;
  isDisabled?: boolean;
  secondaryValue?: unknown;
  tertiaryValue?: unknown;
  availableForSmallEvents?: boolean;
  price: number;
}

export interface IButtonsGroup {
  buttonList?: Array<IButtonGroupButton>;
  onChange?: (value: string, event: MouseEvent<HTMLElement>) => void;
  shouldStayInOneRow?: boolean;
  buttonSections?: Array<ButtonSection>;
  selectedValue?: string | number;
  nonClickable?: boolean;
  disabledItems?: number[];
  isSmallEvent?: boolean;
  hideDisabled?: boolean;
}

export type ButtonSection = {
  sectionTitle: string;
  sectionValue: string;
  buttonList: Array<IButtonGroupButton>;
};

const ButtonsGroup: FC<IButtonsGroup> = ({
  buttonList,
  onChange,
  shouldStayInOneRow,
  buttonSections,
  selectedValue,
  nonClickable,
  disabledItems,
  isSmallEvent,
  hideDisabled,
}) => {
  const [selectedButtonValue, setSelectedButtonValue] = useState<string | number>('');

  const handleChange = (newValue: string, event: MouseEvent<HTMLElement>) => {
    onChange ? onChange(newValue, event) : null;
    setSelectedButtonValue(newValue);
  };

  useEffect(() => {
    if (selectedValue === 'unset') {
      setSelectedButtonValue('');
    }
    if (selectedValue) {
      setSelectedButtonValue(selectedValue);
    }
  }, [selectedValue]);

  return (
    <StyledButtonsGroupContainer>
      <>
        {buttonSections ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
            {buttonSections?.map((section, idx) => (
              <Box
                key={`${section}${idx}`}
                sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Typography variant={'h5'}>{section?.sectionTitle}</Typography>
                <StyledToggleButtonGroup
                  color="secondary"
                  value={selectedButtonValue}
                  numberofbuttons={section?.buttonList?.length}
                  shouldstayinonerow={shouldStayInOneRow ? 1 : 0}
                  exclusive
                  onChange={(event, val) => handleChange(val, event)}>
                  {section?.buttonList?.map(
                    (
                      {
                        title,
                        value,
                        isDisabled,
                        subtitle,
                        additionalValue,
                        secondaryValue,
                        tertiaryValue,
                        price,
                      },
                      idx,
                    ) => {
                      const disabled = isDisabled || disabledItems?.includes(value as number);
                      if (hideDisabled && disabled) {
                        return null;
                      }

                      return (
                        <StyledToggleButton
                          data-testid={`buttonGroupButton-${value}`}
                          key={`${idx}${value}${section?.sectionValue}`}
                          data-sectionvalue={`${section?.sectionValue}`}
                          data-additionalvalue={`${additionalValue}`}
                          data-secondaryvalue={`${secondaryValue}`}
                          data-tertiaryvalue={`${tertiaryValue}`}
                          value={value + '/' + section?.sectionValue}
                          sx={{ minWidth: shouldStayInOneRow ? '100px' : '115px' }}
                          disabled={disabled}>
                          {title}
                          {subtitle && (
                            <StyledButtonSubtitle variant="body2">{subtitle}</StyledButtonSubtitle>
                          )}
                          {!disabled && price > -1 && (
                            <StyledButtonSubtitle variant="body2">
                              {isSmallEvent
                                ? `${formatToUSDollars(2 * price)}`
                                : `${formatToUSDollars(price)}`}
                            </StyledButtonSubtitle>
                          )}
                        </StyledToggleButton>
                      );
                    },
                  )}
                </StyledToggleButtonGroup>
              </Box>
            ))}
          </Box>
        ) : (
          <StyledToggleButtonGroup
            color="secondary"
            value={selectedButtonValue}
            numberofbuttons={buttonList?.length || 0}
            shouldstayinonerow={shouldStayInOneRow ? 1 : 0}
            exclusive
            onChange={(event, val) => (nonClickable ? null : handleChange(val, event))}>
            {buttonList?.map(
              (
                {
                  title,
                  value,
                  isDisabled,
                  subtitle,
                  additionalValue,
                  secondaryValue,
                  tertiaryValue,
                  price,
                },
                idx,
              ) => (
                <StyledToggleButton
                  data-testid={`buttonGroupButton-${value}`}
                  key={`${idx}${value}`}
                  value={value}
                  data-additionalvalue={`${additionalValue}`}
                  data-secondaryvalue={`${secondaryValue}`}
                  data-tertiaryvalue={`${tertiaryValue}`}
                  disableRipple={nonClickable}
                  disableFocusRipple={nonClickable}
                  sx={{
                    minWidth: shouldStayInOneRow ? '100px' : '115px',
                    '&:hover': {
                      backgroundColor: nonClickable ? 'transparent' : '',
                    },
                    cursor: nonClickable ? 'default' : 'pointer',
                  }}
                  disabled={isDisabled || disabledItems?.includes(value as number)}>
                  {title}
                  {subtitle && (
                    <StyledButtonSubtitle variant="body2">{subtitle}</StyledButtonSubtitle>
                  )}
                  {!isDisabled && price > -1 && (
                    <StyledButtonSubtitle variant="body2">
                      {isSmallEvent ? `${formatToUSDollars(2 * price)}` : `${formatToUSDollars(price)}`}
                    </StyledButtonSubtitle>
                  )}
                </StyledToggleButton>
              ),
            )}
          </StyledToggleButtonGroup>
        )}
      </>
    </StyledButtonsGroupContainer>
  );
};

export default ButtonsGroup;
