import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { FC, useEffect, useState } from 'react';

import {
  selectLessonType,
  selectReservationDate,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { setReservationDate } from 'infrastructure/redux/slices/bookingWizard.slice';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import SmallDatePicker from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/components/SmallDatePicker';

import { StyledInfoOutlinedIcon, StyledSearchCard, StyledSearchContainer } from './style';

interface ISearchBar {
  onSearch?: (lessonType: string | undefined, date: Date) => void;
  onDateChange?: (newDate: Date | undefined) => void;
}

const lessonTypeItems = [
  { title: '0.5 Hour', value: 30 },
  { title: '1 Hour', value: 60 },
  { title: 'Small Group', value: 90 },
];

const SearchBar: FC<ISearchBar> = ({ onSearch, onDateChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const reservationDate = useAppSelector(selectReservationDate);
  const selectedLessonType = useAppSelector(selectLessonType);
  const [lessonType, setLessonType] = useState<string | undefined>(
    selectedLessonType?.toString() || '',
  );
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const handleDateChange = (newDate: Date | undefined) => {
    dispatch(setReservationDate({ reservationDate: newDate }));
    if (newDate) {
      setSelectedDate(newDate);
    }
    if (onDateChange) {
      onDateChange(newDate);
    }
  };

  const handleLessonTypeChange = (event: SelectChangeEvent) => {
    const lessonTypeValue = event.target.value as string;
    setLessonType(lessonTypeValue);
  };

  useEffect(() => {
    if (onSearch && selectedDate) {
      onSearch(lessonType, selectedDate);
    }
  }, [lessonType, selectedDate]);

  const tooltip = (
    <Tooltip
      title="Lessons at Five Iron can be booked up to 28 days in advance."
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: '200px',
            fontSize: '12px',
            fontWeight: '500',
          },
        },
      }}
      placement="right">
      <StyledInfoOutlinedIcon />
    </Tooltip>
  );

  return (
    <StyledSearchCard>
      <StyledSearchContainer>
        <Box
          sx={{
            display: 'inline-flex',
            flex: '1 1 auto',
            gap: 4,
            alignItems: 'center',
            width: { xs: '100%', sm: 'auto' },
          }}>
          <SmallDatePicker
            selectedDate={reservationDate}
            onChange={handleDateChange}
            availableNumberOfDaysToChooseFromToday={29}
          />
          {isMobile && tooltip}
        </Box>
        <FormControl sx={{ flex: '1 1 auto', width: { xs: '100%', sm: 'auto' } }}>
          <InputLabel id="lesson_type_select">Lesson Type</InputLabel>
          <Select
            labelId="lesson_type_select"
            sx={{
              '& .MuiSelect-icon': {
                display: 'block',
              },
              minWidth: '200px',
            }}
            id="lesson_type_select"
            name="lessonType"
            value={lessonType}
            label="Lesson Type"
            color="secondary"
            onChange={handleLessonTypeChange}>
            {lessonTypeItems?.map((size) => (
              <MenuItem key={size.title} value={size.value}>
                {size.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!isMobile && tooltip}
      </StyledSearchContainer>
    </StyledSearchCard>
  );
};

export default SearchBar;
