import { createSelector } from '@reduxjs/toolkit';
import { AppointmentTypes, AvailabilityTypes, LocationTypes } from 'common';

import { RootState } from 'infrastructure/redux/store';
import Appointment from 'domain/entities/Appointment';

import { BookingForm } from './bookingWizard.slice';

export const selectSelf = (storeState: RootState): RootState => storeState;

export const selectBookingForm = createSelector(
  selectSelf,
  (state: RootState): BookingForm => state.bookingForm,
);

export const selectLocation = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.location,
);

export const selectExperienceType = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.experienceType,
);

export const selectReservationDate = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.reservationDate,
);

export const selectPartySize = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.partySize,
);

export const selectStartTime = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.startTime,
);

export const selectEndTime = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.endTime,
);

export const selectSessionLength = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.sessionLength,
);

export const selectLessonType = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): number | undefined => bookingForm.lessonType,
);

export const selectInstructor = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.instructor,
);

export const selectEmail = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.email,
);

export const selectAppointments = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): Appointment[] | undefined => bookingForm.appointments,
);

export const selectLocationDetails = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): LocationTypes.SimulatorLocation | undefined =>
    bookingForm.locationDetails,
);

export const selectResourceId = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): number | undefined => bookingForm.resourceId,
);

export const selectConfirmedBooking = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): AppointmentTypes.BookedAppointmentResponse[] | undefined =>
    bookingForm.confirmedBooking,
);

export const selectInstructorDetails = createSelector(
  selectBookingForm,
  (
    bookingForm: BookingForm,
  ): Partial<AvailabilityTypes.LessonAppointmentAvailability> | undefined =>
    bookingForm.instructorDetails,
);
