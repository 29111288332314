import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { AppointmentTypes, LocationTypes } from 'common';
import { BookingWizardFlowTypes } from 'common/dist/infrastructure/modules/appointment/interfaces/AppointmentTypes';
import { UserSummary } from 'common/dist/infrastructure/modules/auth/interfaces/UserTypes';
import { useCallback, useEffect } from 'react';

import {
  ConfirmReservationError,
  IConfirmReservationAdapter,
  ServicePricingError,
} from 'application/modules/bookingWizard/useCases/hooks/useCaseConfirmReservation';
import useCaseGetSimulatorLocations from 'application/modules/bookingWizard/useCases/hooks/useCaseGetSimulatorLocations';
import useCaseExternalNavigation from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateTo';
import Appointment from 'domain/entities/Appointment';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
import { selectAccessToken } from 'infrastructure/redux/slices/auth.selector';
import { clearAuthData } from 'infrastructure/redux/slices/auth.slice';
import {
  selectAppointments,
  selectEndTime,
  selectExperienceType,
  selectInstructor,
  selectLocation,
  selectResourceId,
  selectStartTime,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import {
  setAddons,
  setAppointments,
  setConfirmedBooking,
  setLocationDetails,
} from 'infrastructure/redux/slices/bookingWizard.slice';
import { clearPaymentData, setTotalAmountToPay } from 'infrastructure/redux/slices/payment.slice';
import { clearUserData, setUserSummary } from 'infrastructure/redux/slices/user.slice';
import { getCommitHash } from 'infrastructure/redux/store';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
function getError(
  error: FetchBaseQueryError | SerializedError | undefined,
): ConfirmReservationError | undefined {
  if (!error) {
    return undefined;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (error.status === 400) {
    return ConfirmReservationError.AppointmentUnavailable;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (error.status === 512) {
    return ConfirmReservationError.InvalidPromoCode;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (error.status === 401) {
    return ConfirmReservationError.Unauthorized;
  }
  return ConfirmReservationError.Unknown;
}

function getPricingError(
  error: FetchBaseQueryError | SerializedError | undefined,
): ServicePricingError | undefined {
  if (!error) {
    return undefined;
  }
  return ServicePricingError.Unknown;
}

const useConfirmReservation: IConfirmReservationAdapter = () => {
  const dispatch = useAppDispatch();
  const locationId = useAppSelector(selectLocation);
  const startTime = useAppSelector(selectStartTime);
  const endTime = useAppSelector(selectEndTime);
  const staffId = useAppSelector(selectInstructor);
  const resourceId = useAppSelector(selectResourceId);
  const appointments = useAppSelector(selectAppointments);
  const flowType = useAppSelector(selectExperienceType);
  const isLessonFlow =
    flowType === BookingWizardFlowTypes.Lesson || flowType === BookingWizardFlowTypes.FirstLesson;
  const { result: locations } = useCaseGetSimulatorLocations();

  const [bookAppointment, result] = mboAPI.useBookAppointmentMutation();
  const [getUser] = mboAPI.useLazyGetUserDataQuery();
  const { navigateToSignInPage, navigateToMyBookingsPage } = useCaseExternalNavigation();
  const accessToken = useAppSelector(selectAccessToken);
  const [getPrices, { data: pricingResult, isLoading, error }] =
    mboAPI.useGetAppointmentPricingMutation();

  const handleLogout = () => {
    dispatch(clearUserData());
    dispatch(clearAuthData());
    dispatch(clearPaymentData());
    localStorage.removeItem(`persist:${getCommitHash()}`);
    navigateToSignInPage();
  };

  const handleSaveUserDataToStore = (userSummary: UserSummary | undefined) => {
    dispatch(setUserSummary(userSummary));
  };
  const getLocationDetails = useCallback(
    (locationId?: string) => locations?.find((location) => location.id === locationId),
    [locations],
  );
  const saveLocationDetailsToStore = (selectedSimLocation: LocationTypes.SimulatorLocation) => {
    dispatch(setLocationDetails(selectedSimLocation));
  };

  const handleMyBookingsRedirect = () => {
    navigateToMyBookingsPage();
  };

  useEffect(() => {
    const details = getLocationDetails(locationId);
    if (details) {
      saveLocationDetailsToStore(details);
    }
  }, [getLocationDetails]);

  const handleGetUser = () => {
    if (locationId) {
      return getUser(locationId).unwrap();
    }
  };

  const handleGetPricing = (userSummary?: UserSummary) => {
    if (locationId && startTime && endTime) {
      if (isLessonFlow) {
        const staffIdArray: number[] = appointments?.map((app) => Number(app.staffId)) || [];
        getPrices({
          sessionTypeId: appointments?.[0]?.sessionTypeId,
          email: userSummary?.email,
          startDateTime: startTime,
          endDateTime: endTime,
          locationId: locationId,
          staffIds: staffIdArray,
        });
      } else if (staffId) {
        let staffIdArray;
        if (typeof staffId === 'number') {
          staffIdArray = [staffId];
        } else {
          staffIdArray = staffId.split(',').map(Number);
        }

        getPrices({
          email: userSummary?.email,
          startDateTime: startTime,
          endDateTime: endTime,
          locationId: locationId,
          staffIds: staffIdArray,
        });
      }
    }
  };

  const handleSaveConfirmedBookingToStore = (
    result: AppointmentTypes.BookedAppointmentResponse,
  ) => {
    dispatch(setConfirmedBooking(result));
  };

  const handleSaveAppointmentsToStore = (
    appointments: Appointment[] | undefined,
    total: number | undefined,
    addOn?: AppointmentTypes.AddOnType,
  ) => {
    if (resourceId !== undefined) {
      dispatch(
        setAppointments({ appointments: appointments?.map((value) => ({ ...value, resourceId })) }),
      );
    } else {
      dispatch(setAppointments({ appointments: appointments }));
    }

    dispatch(setAddons(addOn));
    dispatch(setTotalAmountToPay({ total }));
  };

  return {
    error: getError(result.error),
    result: result.data,
    inProgress: result.isLoading,
    bookAppointment,
    handleLogout,
    getUser: handleGetUser,
    handleSaveUserDataToStore,
    accessToken,
    getPricing: handleGetPricing,
    pricingResult,
    isPricingLoading: isLoading,
    pricingError: getPricingError(error),
    handleSaveAppointmentsToStore,
    handleSaveConfirmedBookingToStore,
    handleMyBookingsRedirect,
  };
};

export default useConfirmReservation;
