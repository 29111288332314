import { Box, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { FC } from 'react';

import {
  getLogoLink,
  getMainLogo,
  getMobileLogo,
} from 'infrastructure/targets/web/modules/common/imageUrlHelpers';

const LogoComponent: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <a href={getLogoLink()} target="_blank">
      <Box
        component="img"
        sx={{
          height: 40,
        }}
        alt="Five Iron Golf Logo"
        src={isMobile ? getMobileLogo() : getMainLogo()}
      />
    </a>
  );
};

export default LogoComponent;
