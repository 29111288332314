import { AlertTitle, Link } from '@mui/material';
import React, { FC, useContext } from 'react';

import { TextCopyContext } from 'infrastructure/targets/web/contexts/TextCopyContext';

import { StyledAlert } from './styles';

const ErrorBox: FC = () => {
  const { getTextCopy } = useContext(TextCopyContext);

  return (
    <StyledAlert severity="error">
      <AlertTitle style={{ color: 'inherit' }}>{getTextCopy('errorBox')}</AlertTitle>
      If you are interested in booking an event please{' '}
      <strong>
        <Link
          href={'https://fiveirongolf.com/events/'}
          target={'_blank'}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          click here
        </Link>
      </strong>
    </StyledAlert>
  );
};

export default ErrorBox;
